import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, Spinner } from 'reactstrap'
import AddBanner from './AddBanner';
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import EditBanner from './EditBanner';
import PermissionNotAllowed from './PermissionNotAllowed';


const Banner = (props) => {
    const [addmodal, setAddmodal] = useState(false);
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editmodal, setEditmodal] = useState(false);
    const [editData, setEditData] = useState({});

    const toggleAdd = () => setAddmodal(!addmodal)
    const toggleEdit = (id) => {
        setEditData({})
        setEditmodal(!editmodal);
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setLoader(true);
        http.post(`banner/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                setEditData(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`banner/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        if (checkData(props.permissions) && props.permissions.Banners.list) {
            onApiInteg();
        }
    }, [props.permissions]);
    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <AddBanner user={props.user} addmodal={addmodal} toggleAdd={toggleAdd} setAddmodal={setAddmodal} onApiInteg={onApiInteg} />
            {checkData(editData) &&
                <EditBanner user={props.user} addmodal={editmodal} toggleAdd={toggleEdit} editData={editData} setEditmodal={setEditmodal} onApiInteg={onApiInteg} />
            }
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Manage Banner</CardTitle>
                    {checkData(props.permissions) && props.permissions.Banners.add &&
                        <Button color="primary" className="vs-btn border-0" onClick={toggleAdd}>Add Banner</Button>
                    }
                </CardHeader>
            </Card>
            {(checkData(props.permissions) && props.permissions.Banners.list)?
            <Row>
                {checkData(apiData) ? apiData.map((item, index) => (
                    <div class="col-md-6 col-lg-6">
                        <div class="card events-card my-3">
                            <div class="card-header d-flex flex-wrap justify-content-between align-items-center">
                                <h3 class="mt-0">{item.title}</h3>
                                {checkData(props.permissions) && props.permissions.Banners.edit &&
                                    <Button color="primary" className="vs-btn border-0" onClick={() => toggleEdit(item.id)}>Edit Banner</Button>
                                }
                            </div>
                            <div class="event-card-img">
                                {checkUndeNullBlank(item.image) ?
                                    <img class="img-fluid w-100" src={process.env.REACT_APP_IMAGE_BANNER + item.image} alt="iovivc" />
                                    :
                                    <img class="img-fluid w-100" src="./img/event-main.jpg" alt="iovivc" />
                                }
                            </div>
                        </div>
                    </div>
                )) : ''}
            </Row>
            :
            <PermissionNotAllowed/> }
        </div>
    )
}

export default Banner