import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, Table, Spinner, Col, Form, FormGroup, CardBody } from 'reactstrap'
import AddSpeaker from './AddSpeaker';
import http from '../../CommonAxios'
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

import EditSpeaker from './EditSpeaker';
import { changeIntoDatedmY } from '../../utlis';
import PermissionNotAllowed from './PermissionNotAllowed';
 


const Speakers = (props) => {
    const [addmodal, setAddmodal] = useState(false);
    const [apiData, setApiData] = useState({});
    const [page, setPage] = useState(1);
    const [selectperpage, setSelectperpage] = useState(20);
    const [filterField, setFilterField] = useState({});
    const [currentCount, setCurrentCount] = useState(1);
    const [loader, setLoader] = useState(false);
    const [editmodal, setEditmodal] = useState(false);
    const [editData, setEditData] = useState(false);

    const { register: filterInput, handleSubmit: filterSubmit, formState: { errors }, reset } = useForm();


    const toggleAdd = () => setAddmodal(!addmodal)
    const toggleEdit = (id) => {
        setEditData({});
        setEditmodal(!editmodal);
        let data = { admin_id: checkData(props.user) ? props.user.id : '', id: id }
        setLoader(true);
        http.post(`speaker/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                // setComments(res.data.data[0].long_desc);
                // setpageId(res.data.data[0].id);
                res.data.data[0].status === true ? res.data.data[0].status = '1' : res.data.data[0].status = '0'
                setEditData(res.data.data[0])
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`speaker/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };

    const onFilter = (data, pagePerpage) => {
        pagePerpage.page = 1
        // if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || (checkData(filterDate.length) && filterDate.length > 0) || (checkData(eventDate.length) && eventDate.length > 0)){
        if (checkUndeNullBlank(data.title)) {
            setCurrentCount(1);
            setSelectperpage(selectperpage);
            setFilterField(data);
            onCommonFilter(data, pagePerpage)
        } else {
            Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
        }
    };
    const onCommonFilter = (data, pagePerpage) => {
        let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page
        let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage

        let byData = { title: data.title,page: pageNumber, per_page: perPageCount }
        byData.admin_id = checkData(props.user) ? props.user.id : ''
        setLoader(true);
        http.post(`speaker/list`, byData).then((res) => {
            setLoader(false);
            //if (res.data.s === 1) {
                setApiData(res.data.data)
            //}
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const formReset = () => {
        reset();
        onApiInteg();
    }

    useEffect(() => {
        onApiInteg();
    }, []);

    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <AddSpeaker user={props.user} addmodal={addmodal} toggleAdd={toggleAdd} setAddmodal={setAddmodal} onApiInteg={onApiInteg} />
            {checkData(editData) &&
                <EditSpeaker user={props.user} addmodal={editmodal} toggleAdd={toggleEdit} editData={editData} setEditmodal={setEditmodal} onApiInteg={onApiInteg} />
            }
            <Row>
                <Col sm={12}>
                    <Card className="p-0 manageOrder shadow content-wrapper border-0">
                        <Card className="border-0">
                            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                <CardTitle className="page-title">Manage Speaker</CardTitle>
                                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                                    {checkData(props.permissions) && props.permissions.Speakers.add &&
                                        <Button color="primary" className="vs-btn border-0" onClick={toggleAdd}>Add Speaker</Button>
                                    }
                                </div>
                            </CardHeader>
                            <Form onSubmit={filterSubmit(onFilter)} className="cardFilter">
                                <Row className="mx-0 justify-content-start justify-content-lg-center">
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                        <input {...filterInput("title")} className="form-control-sm form-control" placeholder="Enter title*" id="title" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3} className="btn-container">
                                        <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={formReset} >Reset</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {(checkData(props.permissions) && props.permissions.Speakers.list)?
                                <CardBody className="p-0">
                                    <div className="showing-count">
                                        {/* {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                                            <span class="small">Showing 1 of 20</span> : ''} */}
                                    </div>
                                    <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">S. No.</th>
                                                <th className="text-nowrap">Title</th>
                                                <th className="text-nowrap">Image</th>
                                                <th className="text-nowrap">Designation</th>
                                                <th className="text-nowrap">Created At</th>
                                                <th className="text-nowrap">Updated At</th>
                                                <th className="text-nowrap">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(apiData) ? apiData.map((item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td><img className="img-fluid" src={process.env.REACT_APP_IMAGE_SPEAKER + item.image} alt="iovivc" width="60" /></td>
                                                    <td>{item.designation}</td>
                                                    <td>{changeIntoDatedmY(item.created_at)}</td>
                                                    <td>{changeIntoDatedmY(item.updated_at)}</td>
                                                    <td>
                                                        {checkData(props.permissions) && props.permissions.Speakers.edit &&
                                                            <Button color="primary" className="vs-btn border-0" onClick={() => toggleEdit(item.id)}>Edit</Button>
                                                        }
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={6}><center className="text-danger">No Records Found</center></td></tr>}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            :
                            <PermissionNotAllowed/> }
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Speakers