import React, { useState, useEffect } from "react";
import http from "../../CommonAxios";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Partner = () => {
  const [apiData, setApiData] = useState({});
  const [loader, setLoader] = useState(false);

  const onApiInteg = () => {
    let data = { admin_id: 1 };
    setLoader(true);
    http
      .post(`/partner/front/list`, data)
      .then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          setApiData(res.data);
        }
      })
      .catch(function (err) {
        setLoader(false);
      });
  };
  useEffect(() => {
    onApiInteg();
  }, []);
  const PreviousArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: "#f0411b", fontSize: "2rem", padding: "10px" }} />
        </div>
    );
};

 const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black", borderRadius: "50%" }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronRight} style={{ color: "#f0411b", fontSize: "2rem", padding: "10px" }} />
        </div>
    );
};
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      checkData(apiData) &&
      checkData(apiData.data) &&
      Object.keys(apiData.data).length > 3
        ? 4
        : checkData(apiData) && checkData(apiData.data)
        ? Object.keys(apiData.data).length
        : "",
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            checkData(apiData) &&
            checkData(apiData.data) &&
            Object.keys(apiData.data).length > 1
              ? 2
              : checkData(apiData) && checkData(apiData.data)
              ? Object.keys(apiData.data).length
              : "",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section class="space-shape mt-n75 arrow-wrap shape-mockup-wrap teamall">
      <div
        class="shape-bg background-image"
        style={{ backgroundImage: "url(./img/team_bg_1.png)" }}
      ></div>
      <div class="container z-index-common">
        <div class="title-area text-center">
          <span class="sub-title2">
            <h4 class="sec-title-ndm">Meet Our Partner</h4>
            <div class="line"></div>
            <img
              class="title-shape"
              src="./img/title_shape.png"
              alt="title shape"
            />
          </span>
        </div>
        <Slider {...settings}>
          {checkData(apiData) && checkData(apiData.data)
            ? apiData.data.map((item, index) => (
                <div class="team-grid card ndn-border">
                  <div class="team-grid_img">
                    {checkUndeNullBlank(item.image) ? (
                      <img
                        src={`${apiData.path}/${item.image}`}
                        alt="team image"
                      />
                    ) : (
                      <img src="./img/team_1_1.jpg" alt="iovivc" />
                    )}
                    {/* <div class="plus-icon">+</div>
                            <div class="team-social">
                                <a href="#" tabindex="-1"><i class="fab fa-facebook-f"></i></a>
                                <a href="#" tabindex="-1"><i class="fab fa-twitter"></i></a>
                                <a href="#" tabindex="-1"><i class="fab fa-instagram"></i></a>
                                <a href="#" tabindex="-1"><i class="fab fa-linkedin-in"></i></a>
                            </div> */}
                  </div>
                  <div class="team-grid_info">
                    <h4 class="team-grid_title">
                      <a href={item.website_link} tabindex="-1" target="_blank">
                        {item.title}
                      </a>
                    </h4>
                    <span class="team-grid_desig">{item.designation}</span>
                  </div>
                </div>
              ))
            : ""}
        </Slider>
        <div
          class="row slider-shadow vs-carousel slick-initialized slick-slider"
          data-slide-show="4"
          data-lg-slide-show="3"
          data-sm-slide-show="2"
          data-arrows="true"
        >
          <div class="slick-list draggable">
            <div
              class="slick-track"
              style={{
                opacity: 1,
                width: "4382px",
                transform: "translate3d(-2191px, 0px, 0px)",
                transition: "transform 1000ms ease 0s",
              }}
            >
              <div
                class="col-lg-4 col-sm-6 slick-slide slick-cloned"
                style={{ width: "313px" }}
                tabindex="-1"
                data-slick-index="-4"
                id=""
                aria-hidden="true"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="shape-mockup jump z-index-3 d-none d-xl-block"
        style={{ top: "34%", left: "3%" }}
      >
        <img src="./img/circle-3.png" alt="iovivc" />
      </div>
      <div
        class="shape-mockup jump-reverse z-index-3 d-none d-xl-block"
        style={{ top: "14%", right: "0%" }}
      >
        <img src="./img/circle-4.png" alt="iovivc" />
      </div>
      <div class="section-after">
        <img src="./img/curved-shape-bottom.png" alt="iovivc" />
      </div>
    </section>
  );
};
export default Partner;
