import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, CardBody, Table, Spinner, Form, FormGroup, Button, Col, Badge, Label, Modal, ModalHeader, ModalBody, Pagination, PaginationItem, PaginationLink, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, } from 'reactstrap'
import { ArrowDown, ArrowUp, ChevronLeft,Search, ChevronRight, ChevronsRight, Underline } from 'react-feather';
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { changeIntoDatedmY,changeIntoDatedmYHMS } from '../../utlis';
import Swal from 'sweetalert2';
import { nonMemberCategory,Usertype,Activities,Activities_1,Activities_2,Activities_3 } from '../../Config';
 import PermissionNotAllowed from './PermissionNotAllowed';
 
import QrReader from 'modern-react-qr-reader'
 



const ActivityList = (props) => {
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
    const [logData, setLogData] = useState({});
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [page, setPage] = useState(1);
    const [selectperpage, setSelectperpage] = useState(20);
    const [currentCount, setCurrentCount] = useState(1);
    const [filterField, setFilterField] = useState({});
  
    const [result, setResult] = useState('');
    const [ActivityID, setActivityID] = useState('');
    

     const [vibrate, setVibrate] = useState(false);

    const [modalDownload, setModalDownload] = useState(false);

 
    const dropFilterCount = 20;
   
     
    const handleScan = (data) => {
        if (data) {
         // alert("calling");  
    var promise = new Promise( (resolve, reject) => {
    
        //   setResult((prevResult) => {
            
        //     navigator.vibrate(200);
        //     setVibrate(true);
        //     setTimeout(() => setVibrate(false), 300);
             
        //    // let scanIdsString= prevResult ? `${prevResult},${data}` : data;
        //     let scanIdsString=  data;
        //     alert(scanIdsString);

        //     const scanIdsArray = scanIdsString.split(',');
        //     const uniqueScanIdsSet = new Set(scanIdsArray);
        //     const uniqueScanIdsString = Array.from(uniqueScanIdsSet).join(',');
        //     return uniqueScanIdsString;

        //   });
         // alert(data);

          setResult(data);
          navigator.vibrate(200);
          setVibrate(true);
          setTimeout(() => setVibrate(false), 300);
          //alert(2);

          resolve("Promise resolved successfully");


        });

        promise.then(res=>{
            //alert("promise then");        
            let login_id = checkData(props.user) ? props.user.id : '';

            var SendData={"register_id":data,'activity_id':ActivityID,'user_id':login_id};
            
            if(data === ""){
                Swal.fire({ icon: 'error', text: "Register Id not found", title: 'Error' });
                return false;
            } 
            if(Number(ActivityID) === 0){
                Swal.fire({ icon: 'error', text: "Choose Activity", title: 'Error' });
                return false;
            } 
            if(Number(login_id) === 0){
                Swal.fire({ icon: 'error', text: "Login ID not found", title: 'Error' });
                return false;
            }
            labelDownloadCommon(SendData);
                
        });
         
          

        }
      };

      const handleError = (err) => {
        console.error(err);
      };
    
      const clearResult = () => {
        setResult('');
      };

      const toggleDownload = () => {
        clearResult();
        setModalDownload(!modalDownload)
       };

      
      const { register: bulkLabel, handleSubmit: bulkLabelDownload, formState: { errors: errormanlabel } } = useForm();

      const labelDownload = (data) => {
         // setLoader(true);
        // alert(data.register_ids);
        var lastData = data.register_ids.slice(-1);
        data.user_id = checkData(props.user) ? props.user.id : '';
         data.register_id = data.register_ids.split(',')
        // labelDownloadCommon(data);
        if (lastData === ",") {
          data.register_id.pop();
        }   
        
        if (data.register_id.length>0) {
          labelDownloadCommon(data);
        } else {
          Swal.fire({ icon: 'error', text: "Register Id not found", title: 'Error' });
        }
    
      }

      const labelDownloadCommon = (data) => {
       // alert(JSON.stringify(data));
        setLoader(true);
        http.post(`event/activity/save`, data).then((res) => {
            setLoader(false);

          if (res.data.error === false && res.data.msg!==undefined && res.data.msg!=="") {
          
    
            Swal.fire({
                text: res.data.msg,
                icon: 'none',
                timer:2000,
                confirmButtonText:false,
                customClass: {content: 'small-font'},
            });

            setTimeout(function(){
                onApiInteg();
                clearResult()
            },2000)
          }else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', });
          }

        }).catch((err) => {
          alert("Error In genreating "+err.stack);
            //errorHandling(err, "")
          setLoader(false);
        });
      }

    

      const getActivityName =(activity_id)=>{
        let activtiy=(Activities[activity_id]!==undefined)?Activities[activity_id]:"NA";
        return activtiy;
      };


      

    const toggleView = (id) => {
        setViewModal(!viewModal)
        let data = { admin_id: checkData(props.user) ? props.user.id : '', "register_id": id }
        setLoader(true);
        http.post(`event/registration/view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setViewData(res.data.data[0]);
                setLogData(res.data.log);
            }
        }).catch(function (err) {
            setLoader(false);
        });
    }
    const getUsernameFromId=(id)=>{
       let  USER_TYPES=Usertype;
       if(Number(id)>0){
        let type=(USER_TYPES[id]!==undefined)?USER_TYPES[id]:"";
        return type;
       }else{
        return 'NA';
       }


    }
    
    
    

    const onApiInteg = () => {
        setFilterField({});
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`event/activity/list`, data).then((res) => {
            setLoader(false);

            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {
        onApiInteg();
    }, [result])
    
    const { register: filterInput, handleSubmit: filterSubmit, formState: { errors }, reset } = useForm();
    const onFilter = (data, pagePerpage) => {
        pagePerpage.page = 1
        // if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || (checkData(filterDate.length) && filterDate.length > 0) || (checkData(eventDate.length) && eventDate.length > 0)){
        if (checkUndeNullBlank(data.activity_id) || checkUndeNullBlank(data.register_id)) {
            setCurrentCount(1);
            setSelectperpage(selectperpage);
            setFilterField(data);
            onCommonFilter(data, pagePerpage)
        } else {
            Swal.fire({
                text: 'Please choose filter',
                width:'300px',
                icon: 'none', // Set icon to 'none' to remove the default icon
                customClass: {
                  content: 'small-font', // Apply a custom class to the content for styling
                },
                timer: 2000, 
                confirmButtonText: false,
              });
        }
    };
    const formReset = () => {
        reset();
        onApiInteg();
    }



    function previousPageData() {
        if (page > 1) {
            setPage(page - 1)
            checkData(apiData.data)
            if (currentCount > Object.entries(apiData.data).length) {
                setCurrentCount(currentCount - selectperpage)
            } else {
                setCurrentCount(1)
            }
            let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
            onpaginationFilter(filterField, pagePerpage);
        }
    }

    function handleSelectChange(event) {
        setSelectperpage(event.target.value);
        // setLoadertable(true)
        setPage(1)
        setCurrentCount(1)
        let pagePerpage = { "page": 1, "per_page": event.target.value };
        onpaginationFilter(filterField, pagePerpage)
    }
    function nextPageData() {
        if (checkData(apiData.data) && checkUndeNullBlank(apiData.totalPage)) {
            // &&checkUndeNullBlank(apiData.totalPage).length>page.length
            if (apiData.totalPage > page) {
                setCurrentCount(currentCount + Object.entries(apiData.data).length)
                setPage(page + 1)
                // setLoadertable(true)
                let pagePerpage = { "page": page + 1, "per_page": selectperpage };
                onpaginationFilter(filterField, pagePerpage);
            }
        }
    }


    const onpaginationFilter = (data, pagePerpage) => {
        onCommonFilter(data, pagePerpage)
    };

    const getActivityId=(e)=>{
        var text = e.target.value;
        setActivityID(text);
    }

    const seperateAWBno = (e) => {
        var text = e.target.value;
        setResult(text);
        
        // var lastData = text.slice(-1);
        // var inputData = text;
        // var newText = "";
        // var listData = "";
        // listData = text.replace(/\n|\r/g, "");
        // listData = inputData.split(/,+/);
        // if (lastData === ",") {
        //   newText = listData.toString();
        //   setResult(newText);
        // }
    
        
      }

    const onCommonFilter = (data, pagePerpage) => {
        let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page
        let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage

        let byData = {  activity_id: data.activity_id, register_id: data.register_id, page: pageNumber, per_page: perPageCount }
        byData.admin_id = checkData(props.user) ? props.user.id : ''
        setLoader(true);
        http.post(`event/activity/list`, byData).then((res) => {
            setLoader(false);
            //if (res.data.s === 1) {
            setApiData(res.data)
            //}
        }).catch(function (err) {
            setLoader(false);
        });
    };
    const exportShipment = () => {
        // if (checkData(filterField)) {
        let data = filterField
        data.admin_id = checkData(props.user) ? props.user.id : ''
        data.is_export = 1
        setLoader(true);
        http.post(`event/activity/export`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                window.location.href = res.data.file;
            }
        }).catch(function (err) {
            setLoader(false);
        });

        // } else {
        //     Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
        // }
    }
    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}

            <Modal isOpen={modalDownload} toggle={toggleDownload} size="lg" scrollable={true}>
                <ModalHeader toggle={toggleDownload}>Member Activities</ModalHeader>
                <ModalBody>
                  <Form onSubmit={bulkLabelDownload(labelDownload)}>
                    <Row className="mx-0 justify-content-center downloadModal">
                    <QrReader
                            delay={300}
                            facingMode={"environment"}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                            />
                    <Col className='mt-4' sm={12}>
                        <FormGroup>
                            <FormGroup check className="p-0">
                                <Label>Select Activity<span className="text-danger">*</span></Label>
                                <div>
                                    <Table responsive className="border-none text-center custom-table">
                                    <thead>
                                            <tr>
                                                <th colSpan={4} className="text-nowrap"><center> DAY 1 </center></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         <tr>
                                            {Object.keys(Activities_1).map(key=>
                                                <>
                                                <td  className="me-3">
                                                    <input className="mx-2" {...bulkLabel("activity_id", { required:  'Please choose one activity' })} onClick={e => getActivityId(e)}  type="radio" value={ key } id={`${ key > 0 ? key+"_act":"" }`} />
                                                    <Label for={`${ key > 0 ? key+"_act":"" }`}>{ Activities_1[key].replace("Day 1 - ","") }</Label>
                                                </td>
                                                 
                                                </>
                                                )} 
                                               
                                            </tr>
                                         </tbody>                  
                                    </Table>

                                    <Table responsive className="border-none text-center custom-table">
                                    <thead>
                                            <tr>
                                                <th colSpan={4} className="text-nowrap"><center> DAY 2 </center></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         <tr>
                                            {Object.keys(Activities_2).map(key=>
                                                <>
                                                <td  className="me-3">
                                                    <input className="mx-2" {...bulkLabel("activity_id", { required:  'Please choose one activity' })}  onClick={e => getActivityId(e)} type="radio" value={ key } id={`${ key > 0 ? key+"_act":"" }`} />
                                                    <Label for={`${ key > 0 ? key+"_act":"" }`}>{ Activities_2[key].replace("Day 1 - ","") }</Label>
                                                </td>
                                                 
                                                </>
                                                )} 
                                             </tr>
                                         </tbody>                  
                                    </Table>

                                    <Table responsive className="border-none text-center custom-table">
                                    <thead>
                                            <tr>
                                                <th colSpan={4} className="text-nowrap"><center> DAY 3 </center></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                         <tr>
                                            {Object.keys(Activities_3).map(key=>
                                                <>
                                                <td  className="me-3">
                                                    <input className="mx-2" {...bulkLabel("activity_id", { required:  'Please choose one activity' })}  onClick={e => getActivityId(e)} type="radio" value={ key } id={`${ key > 0 ? key+"_act":"" }`} />
                                                    <Label for={`${ key > 0 ? key+"_act":"" }`}>{ Activities_3[key].replace("Day 1 - ","") }</Label>
                                                </td>
                                                 
                                                </>
                                                )} 
                                             </tr>
                                         </tbody>                  
                                    </Table>
                                    
                                  
                                </div>
                                {errormanlabel.activity_id && <span className="text-danger d-block error">{errormanlabel.activity_id.message}</span>}

                            </FormGroup>
                        </FormGroup>
                        </Col>

                      <Col className='mt-4' sm={12}>
                        <FormGroup>
                          <Label for="exampleText">
                          <br></br> Scan or Enter a Valid Register ID by Comma Seperated</Label>
                          <textarea {...bulkLabel("register_ids")} style={{"height":"100px"}} value={result} onChange={e => seperateAWBno(e)}  rows="8" className="form-control-sm form-control" placeholder="Please Enter Comma Seperated Register ID" id="exampleText" />
                          {/* <Input type="textarea" name="text" id="exampleText" rows="15" /> */}
                          {/* <span className="small" > 500 Docket Numbers (approx) or 5000 Characters Allowed</span> */}
                          {errormanlabel.register_ids && <span className="text-danger d-block error">{errormanlabel.register_ids.message}</span>}
                         </FormGroup>
                      </Col>

                      
                      
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>


            {checkData(viewData) &&
                <Modal isOpen={viewModal} toggle={viewModal} scrollable={true} size="lg" className='addModal'>
                    <ModalHeader toggle={toggleView}>Registered Member  { viewData.register_id }</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row className='mx-0'>
                            <Col sm={12} xl={12}>

                            <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">Register ID</th>
                                                <th className="text-nowrap">Activity</th>
                                                <th className="text-nowrap">Created</th>
                                                <th className="text-nowrap">Updated</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(logData)  ? logData.map((item, index) => (
                                                <tr>
                                                    <td onClick={() => toggleView(item.register_id)} style={{"cursor":"pointer","text-decoration":"underline"}} className="align-middle"> {item.register_id || "NA" } </td>
                                                    <td className="align-middle">{getActivityName(item.activity_id) } </td>
                                                    <td className="align-middle">{changeIntoDatedmYHMS(item.created_at)} </td>
                                                    <td className="align-middle">{changeIntoDatedmYHMS(item.updated_at)} </td>
                                                </tr>
                                            )) : <tr><td colSpan={4}><center className="text-danger">No Records Found</center></td></tr>}
                                        </tbody>
                                    </Table>
                               </Col>   
                            </Row>
                            <Row className='mt-2 mb-2'>
                                <Col sm={12} xl={12}>
                                    <h4><center> <u> Registration Details </u> </center></h4>
                                </Col>
                            </Row>
                            <Row className="mx-0  addModal border">


                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <div>
                                            {viewData.first_name} {viewData.last_name} <br></br>
                                            <small><strong> { getUsernameFromId(viewData.user_type_id) }</strong></small>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <div>
                                            {viewData.email || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Phone</Label>
                                        <div>
                                            {viewData.phone || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Member Type ID</Label>
                                        <div>
                                            {parseInt(viewData.member_type_id) === 1 ? 'Member' : 'Non-Member'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Non-Member Category</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.non_member_category_id) ?
                                                <>
                                                    {nonMemberCategory[viewData.non_member_category_id]}
                                                </> :
                                                'NA'
                                            }
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>IOV Number</Label>
                                        <div>
                                            {viewData.iov_number || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>IBBI Number</Label>
                                        <div>
                                            {viewData.ibbi_no || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Order ID</Label>
                                        <div>
                                            {viewData.order_id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Register ID</Label>
                                        <div>
                                            {viewData.id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Payment ID</Label>
                                        <div>
                                            {viewData.payment_id || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Total Amount</Label>
                                        <div>
                                            {viewData.total_amt || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Status</Label>
                                        <div>
                                            {checkUndeNullBlank(viewData.pay_status) && viewData.pay_status ? <Badge color="success">Paid</Badge> : <Badge color="danger">UnPaid</Badge>}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Date</Label>
                                        <div>
                                            {viewData.pay_date || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pay Remarks</Label>
                                        <div>
                                            {viewData.remarks || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>City</Label>
                                        <div>
                                            {viewData.city || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>State</Label>
                                        <div>
                                            {viewData.state || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Country</Label>
                                        <div>
                                            {viewData.country || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Pincode</Label>
                                        <div>
                                            {viewData.pincode || 'NA'}
                                        </div>
                                    </FormGroup>
                                </Col>
                                {checkUndeNullBlank(viewData.gst_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>GST No.</Label>
                                            <div>
                                                {viewData.gst_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.passport_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Passport No.</Label>
                                            <div>
                                                {viewData.passport_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.business_visa_no) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Visa No.</Label>
                                            <div>
                                                {viewData.business_visa_no || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Created At</Label>
                                        <div>
                                            {changeIntoDatedmY(viewData.created_at)}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={6} xl={4}>
                                    <FormGroup>
                                        <Label>Updated At</Label>
                                        <div>
                                            {changeIntoDatedmY(viewData.updated_at)}
                                        </div>
                                    </FormGroup>
                                </Col>
                                
                                {checkUndeNullBlank(viewData.delegate_name_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Name</Label>
                                            <div>
                                                {viewData.delegate_name_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_age_1) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Age</Label>
                                            <div>
                                                {viewData.delegate_age_1 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_gender_1) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate One Gender</Label>
                                            <div>
                                                {viewData.delegate_gender_1 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_name_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Name</Label>
                                            <div>
                                                {viewData.delegate_name_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_age_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Age</Label>
                                            <div>
                                                {viewData.delegate_age_2 || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                                {checkUndeNullBlank(viewData.delegate_gender_2) &&
                                    <Col sm={6} xl={4}>
                                        <FormGroup>
                                            <Label>Delegate Two Gender</Label>
                                            <div>
                                                {viewData.delegate_gender_2 || 'NA' || 'NA'}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                }
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            }
            <Row>
                <Col sm={12}>
                    <Card className="p-0 manageOrder shadow content-wrapper border-0">
                        <Card className="border-0">
                            <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                                <CardTitle className="page-title">Manage Member Activities </CardTitle>
                                <div className="">
                                    {checkData(props.permissions) && props.permissions.Registration.export &&
                                        <span onClick={exportShipment} className="cursor-pointer me-2 btn btn-primary">
                                            <span className="icon ms-2" ><ArrowDown /> </span><span>Download</span>
                                        </span>
                                    }
                                    {checkData(props.permissions) && props.permissions.Registration.export &&
                                        <span className="cursor-pointer mt-1 btn btn-primary" onClick={toggleDownload}>
                                            <span className="icon ms-2 cursor-pointer"><Search /> </span><span>Scan</span>
                                        </span>
                                    }
                                </div>
                            </CardHeader>
                            <Form onSubmit={filterSubmit(onFilter)} className="cardFilter pb-3">
                                <Row className="mx-0 justify-content-start justify-content-lg-center">
                                   
                                    <Col sm={6} md={6} lg={4} xl={3}>
                                        <FormGroup>
                                            <select {...filterInput("activity_id")} className="form-control" id="activity_id">
                                                <option value="">Select Activity</option>
                                                {Object.keys(Activities).map(key=>
                                                <>
                                                    <option value={ key }>{ Activities[key] ?? 'NA' }</option>
                                                </>
                                                )};    
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6} lg={4} xl={4}>
                                        <FormGroup>
                                            <input {...filterInput("register_id")} className="form-control" id="register_id" placeholder="Search By Comma Seperated Register ids" />
                                        </FormGroup>
                                    </Col>
                                   
                                   
                                    <Col sm={3} className="btn-container">
                                        <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={formReset}>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>
                            {(checkData(props.permissions) && props.permissions.Registration.list) ?
                                <CardBody className="p-0">
                                    <div className="showing-count px-3 py-2">
                                        {checkData(apiData) && checkData(apiData.data) && checkUndeNullBlank(apiData.TotalRows) ?
                                            <span className="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(apiData.data).length)} of {apiData.TotalRows}</span> : ''}
                                    </div>
                                    <Table responsive className="text-center custom-table">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">Register ID</th>
                                                <th className="text-nowrap">Activity</th>
                                                <th className="text-nowrap">Created</th>
                                                <th className="text-nowrap">Updated</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkData(apiData) && checkData(apiData.data) ? apiData.data.map((item, index) => (
                                                <tr>
                                                    <td onClick={() => toggleView(item.register_id)} style={{"cursor":"pointer","text-decoration":"underline"}} className="align-middle"> {item.register_id || "NA" } </td>
                                                    <td className="align-middle">{getActivityName(item.activity_id) } </td>
                                                    <td className="align-middle">{changeIntoDatedmYHMS(item.created_at)} </td>
                                                    <td className="align-middle">{changeIntoDatedmYHMS(item.updated_at)} </td>
                                                </tr>
                                            )) : <tr><td colSpan={6}><center className="text-danger">No Records Found</center></td></tr>}
                                        </tbody>
                                    </Table>
                                    {checkData(apiData) && checkData(apiData.data) && checkUndeNullBlank(apiData.TotalRows) ?
                                        <>
                                            {apiData.TotalRows > dropFilterCount ?
                                                <Row className="m-0 table-footer">
                                                    <Col xs="6">
                                                        <Pagination aria-label="Page navigation example align-items-center">
                                                            <PaginationItem className="prev">
                                                                <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center me-3"><ChevronLeft /></PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem className="next ml-3">
                                                                <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                                                            </PaginationItem>
                                                        </Pagination>
                                                    </Col>
                                                    <Col xs="6">
                                                        <Form>
                                                            <FormGroup>
                                                                {/* <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span> */}
                                                                <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control w-auto ms-auto pe-4">
                                                                    <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                                                    <option value="20">20</option>
                                                                    <option value="50">50</option>
                                                                    <option value="250">250</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Form>
                                                    </Col>
                                                </Row> : ''}
                                        </>
                                        : ''}
                                </CardBody>
                                :
                                <PermissionNotAllowed />}
                        </Card>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ActivityList