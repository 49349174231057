import React, { useState, useEffect } from 'react'
import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import NavMenu from './components/frontend/NavMenu';
import Home from './components/frontend/Home';
import About from './components/frontend/About';
import Footer from './components/frontend/Footer';
import Sidebar from './components/backend/Sidebar';
import Header from './components/backend/Header';
import { useLocation } from 'react-router';
import Login from './components/backend/Login';
import Event from './components/backend/Event';
import Gallery from './components/backend/Gallery';
import Banner from './components/backend/Banner';
import CMS from './components/backend/CMS';
import SiteSetting from './components/backend/SiteSetting';
import { encryptStorageLogin } from './EncStorage';
import { checkData, checkUndeNullBlank } from './ObjectExist';
import Contact from './components/frontend/Contact';
import EventList from './components/frontend/EventList';
import EventsDetails from './components/backend/EventsDetails';
import ContactList from './components/backend/ContactList';
import Speakers from './components/backend/Speakers';
import Partners from './components/backend/Partners';
import Popup from './components/backend/Popup';
import Dashboard from './components/backend/Dashboard';
import CmsPages from './components/frontend/CmsPages';
import http from './CommonAxios'
import { Helmet } from "react-helmet";
import Register from './components/frontend/Register';
import Faq from './components/frontend/Faq';
import EventItem from './components/backend/EventItem';
import EventGallery from './components/frontend/EventGallery';
import ResponseP from './components/frontend/ResponseP';
import RegistartionList from './components/backend/RegistrationList';
import AllAdmin from './components/backend/AllAdmin';
import ActivityList from './components/backend/ActivityList';

import { iovivcMetadata } from './Config';



function App() {
  const urlPath = useLocation();
  const userdec = encryptStorageLogin.getItemFromPattern('userenc');
  const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && checkUndeNullBlank(userdec.userenc.data) ? userdec.userenc.data : userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc;
  const [apiData, setApiData] = useState({});
  const [permissions, setPermissions] = useState({});

  const [loader, setLoader] = useState(false);


  const onApiInteg = () => {
    let data = { admin_id: 1 }
    setLoader(true);
    http.post(`admin/settings-view`, data).then((res) => {
      setLoader(false);
      if (res.data.s === 1) {
        setApiData(res.data)
        // reset(res.data.data)
      }
    }).catch(function (err) {
      setLoader(false);
    });
  };

  const permAPI = () => {
    if (checkData(user) && checkUndeNullBlank(user[0].id)) {
      let data = { id: checkUndeNullBlank(user[0].id) ? user[0].id : '', admin_id: 1 }
      setLoader(true);
      http.post(`admin/view`, data).then((res) => {
        setLoader(false);
        if (res.data.s === 1) {
          if (checkData(res.data.data)) {
            setPermissions(JSON.parse(res.data.data[0].permissions))
          }
        }
      }).catch(function (err) {
        setLoader(false);
      });
    }
  }

  useEffect(() => {
    onApiInteg();
  }, []);
  let tempObj = {}
  if (checkData(apiData) && checkData(apiData.data)) {
    tempObj.path = apiData.path
    apiData.data.map((item, index) => {
      if (item.option_key === 'address') {
        tempObj.address = item.option_value
      } else if (item.option_key === 'twitter_url') {
        tempObj.twitter_url = item.option_value
      } else if (item.option_key === 'linkedin') {
        tempObj.linkedin = item.option_value
      } else if (item.option_key === 'facebook') {
        tempObj.facebook = item.option_value
      } else if (item.option_key === 'logo') {
        tempObj.logo = item.option_value
      } else if (item.option_key === 'favicon') {
        tempObj.favicon = item.option_value
      }else if (item.option_key === 'footer_logo') {
        tempObj.footer_logo = item.option_value
    } else if (item.option_key === 'top_right_logo') {
        tempObj.top_right_logo = item.option_value
    } else if (item.option_key === 'meta_title') {
        tempObj.meta_title = item.option_value
      } else if (item.option_key === 'meta_keywords') {
        tempObj.meta_keywords = item.option_value
      } else if (item.option_key === 'meta_description') {
        tempObj.meta_description = item.option_value
      }else if (item.option_key === 'footer_about') {
        tempObj.footer_about = item.option_value
      }else if (item.option_key === 'phone') {
        tempObj.phone = item.option_value
      }else if (item.option_key === 'email_for_contact') {
        tempObj.email_for_contact = item.option_value
      }else if (item.option_key === 'company_name') {
        tempObj.company_name = item.option_value
      }
      return true
    });
  }
  //console.log('tempObj', tempObj)
  const path = urlPath.pathname

  return (
    <div className="App">
      {urlPath.pathname !== '/dashboard' && urlPath.pathname !== '/admin' && urlPath.pathname !== '/adminlogin' && urlPath.pathname !== '/event' && urlPath.pathname !== '/gallery' && urlPath.pathname !== '/banner' && urlPath.pathname !== '/users'
         && urlPath.pathname !== '/activityList' && urlPath.pathname !== '/cms' && urlPath.pathname !== '/popup' &&  urlPath.pathname !== '/sitesetting' && urlPath.pathname !== '/contactList' && urlPath.pathname !== '/speakers' && urlPath.pathname !== '/partners' && urlPath.pathname !== '/eventitem' && urlPath.pathname !== '/registrationList' ?
        <>
          <NavMenu tempObj={tempObj} />
          <Helmet>
            <meta charSet="utf-8" name="keywords" content={iovivcMetadata[path]?.metaKeywords ?? tempObj.meta_keywords} />
            <meta name="google-site-verification" content="5TjAQnlod7S28xSKbWdQ1wGf96XqCahiyzQWK6t_ccQ" />
            <meta name="description" content={iovivcMetadata[path]?.description ?? tempObj.meta_description} />
            <meta name="facebook-domain-verification" content="03qnzi5zqprd0f69j6d1b87j7lllh3" /> 
            <title>{iovivcMetadata[path]?.title ?? tempObj.meta_title}</title>
            <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
          </Helmet>
        </>
        :
        checkData(user) && urlPath.pathname !== '/adminlogin' ?
          <>
            <Header user={user[0]}  permAPI={permAPI} />
            <Sidebar user={user[0]} />
          </>
          : ''
      }
      <Routes>
        <Route path="/" element={<Home />} />
        {checkData(user) && <>
          <Route path="/dashboard" element={<Dashboard user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/event" element={<Event user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/gallery" element={<Gallery user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/banner" element={<Banner user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/cms" element={<CMS user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/sitesetting" element={<SiteSetting user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/contactList" element={<ContactList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/registrationList" element={<RegistartionList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/activityList" element={<ActivityList user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/speakers" element={<Speakers user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/popup" element={<Popup user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/partners" element={<Partners user={user[0]} permissions={checkData(permissions) && permissions} />} />
          <Route path="/eventitem" element={<EventItem user={user[0]} permissions={checkData(permissions) && permissions} />} />
          {parseInt(user[0].id) === 1 &&
            <Route path="/users" element={<AllAdmin user={user[0]} />} />
          }
        </>
        }
        <Route path="/adminlogin" element={<Login />} />
        {/* <Route path="/page/:slug" element={<About />} /> */}
        <Route path="/contact" element={<Contact tempObj={tempObj} />} />
        <Route path="/ivc-event-schedule" element={<EventList />} />
        <Route path="/register" element={<Register />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/eventsDetails" element={<EventsDetails />} />
        <Route path="/event/gallery" element={<EventGallery />} />
        <Route path="/page/:slug" element={<CmsPages />} />
        <Route path="/event/registration/response" element={<ResponseP />} />

        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
      {urlPath.pathname !== '/dashboard' && urlPath.pathname !== '/admin' && urlPath.pathname !== '/adminlogin' && urlPath.pathname !== '/event' && urlPath.pathname !== '/gallery' && urlPath.pathname !== '/banner' && urlPath.pathname !== '/users'
        && urlPath.pathname !== '/cms' && urlPath.pathname !== '/activityList'  &&  urlPath.pathname !== '/popup' && urlPath.pathname !== '/sitesetting' && urlPath.pathname !== '/contactList' && urlPath.pathname !== '/speakers' && urlPath.pathname !== '/partners' && urlPath.pathname !== '/eventitem' && urlPath.pathname !== '/registrationList' &&
        <Footer tempObj={tempObj} />
      }
    </div>
  );
}

export default App;
