// App.js
import React, { useState, useEffect } from 'react';
// import './App.css';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import http from '../../CommonAxios'
import { checkData, checkUndeNullBlank } from '../../ObjectExist';

function Sliderr() {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [apiData, setApiData] = useState({});
    const [loader, setLoader] = useState(false);

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`/speaker/front/list`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                setApiData(res.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    useEffect(() => {

        setNav1(slider1);
        setNav2(slider2);
        onApiInteg();

    },[]);


    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };

 

    return (
        <>
            <section class="space teamall">
                <div class="container">
                    <div class="row title-wrap">
                        <div class="col-md-auto">
                            <div class="title-area ">
                                <span class="sub-title ">#Meet With IOV Speakers</span>
                                <h2 class="sec-title ">Our Speakers</h2>
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <a href="./" class="vs-btn style2">All Speakers</a>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-xl-5 col-lg-6">
                            <div class="speaker-img-slider vs-carousel">
                                <Slider
                                    {...settingsMain}
                                    asNavFor={nav2}
                                    ref={slider => (setSlider1(slider))}
                                >
                                    {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (

                                        <div class="speaker-img" key={item.id}>
                                            {checkUndeNullBlank(item.image) ?
                                                <img className="slick-slide-image" src={`${apiData.path}/${item.image}`} alt="iovivc" />
                                                : <img src="./img/team_1_1.jpg" alt="iovivc" />
                                            }
                                        </div>
                                    )) : ''}

                                </Slider>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-6">
                            <div class="speaker-slider-wrapper">
                                <div class="speaker-about-slider vs-carousel">

                                    <Slider
                                        {...settingsMain}
                                        asNavFor={nav2}
                                        ref={slider => (setSlider1(slider))}
                                    >
                                        {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (

                                            <div class="speaker-about slick-slide" key={item.id}>
                                                <span class="speaker-desig">{item.designation}</span>
                                                <h3 class="h2 speaker-name">{item.title}</h3>
                                                <p class="speaker-text">Seamlessly initiate prospective applications rather than competitive schemas. goal Proactively conceptualize covalent benefits after multifunctional schemas. Globally streamline corporate internal or "organic" sources after</p>
                                            </div>
                                        )) : ''}

                                    </Slider>
                                </div>
                                <div class="row speaker-avater-slider vs-carousel">
                                    <Slider
                                        {...settingsThumbs}
                                        asNavFor={nav1}
                                        ref={slider => (setSlider2(slider))}>
                                        {checkData(apiData) && checkData(apiData) ? apiData.data.map((item, index) => (


                                            <div class="slick-slide" key={item.id}>
                                                <div class="speaker-avater">
                                                    {checkUndeNullBlank(item.image) ?
                                                        <img className="slick-slide-image" src={`${apiData.path}/${item.image}`} alt="iovivc" />
                                                        : <img src="./img/team_1_1.jpg" alt="iovivc" />
                                                    }
                                                </div>
                                            </div>
                                        )) : ''}

                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="App">
                <div className="slider-wrapper">

                    {/* <Slider
                        {...settingsMain}
                        asNavFor={nav2}
                        ref={slider => (setSlider1(slider))}
                    >

                        {slidesData.map((slide) =>

                            <div className="slick-slide" key={slide.id}>
                                <h2 className="slick-slide-title">{slide.title}</h2>
                                <img className="slick-slide-image" src={`https://picsum.photos/800/400?img=${slide.id}`} />
                                <label className="slick-slide-label">{slide.label}</label>
                            </div>

                        )}

                    </Slider> */}
                    <div className="thumbnail-slider-wrap">
                        {/* <Slider
                            {...settingsThumbs}
                            asNavFor={nav1}
                            ref={slider => (setSlider2(slider))}>

                            {slidesData.map((slide) =>

                                <div className="slick-slide" key={slide.id}>
                                    <img className="slick-slide-image" src={`https://picsum.photos/800/400?img=${slide.id}`} />
                                </div>

                            )}

                        </Slider> */}
                    </div>
                </div>

            </div>
        </>
    );
}

export default Sliderr;