import React, { useState, useEffect } from 'react'
import {checkData , checkUndeNullBlank } from '../../ObjectExist';
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser';


const CmsPages = () => {
    let cmsId = (new URLSearchParams(window.location.search)).get("s");
    const [bgImage, setBgImage] = useState(false);
    const { slug } = useParams();
    const [activeKey, setActiveKey] = useState(null);
    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
      };
    useEffect(() => {
        setBgImage("breadcrumb-bg.jpg");        
        window.scrollTo(0, 0)
    }, [slug]);
    //console.log('apiData', apiData)
    return (
        <div className="element">
            <div class="breadcumb-wrapper background-image">
                <img src={`${process.env.REACT_APP_BASE_URL}/img/${bgImage}`} alt="iovivc" className="w-100 img-fluid" />
            </div>
            
            <section class="space">
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-12 align-self-center">
                            




                        <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={`accordion-button ${activeKey === 'collapseOne' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseOne')}
            aria-expanded={activeKey === 'collapseOne'}
            aria-controls="collapseOne"
          >
           <strong>(1)</strong> &nbsp;  Who is organizing the Indian Valuers Congress "IVC - 2024"?
          </button>
        </h2>
        <div
          id="collapseOne"
          className={`accordion-collapse collapse ${activeKey === 'collapseOne' ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
          <strong>Indian Valuers Congress (IVC),</strong> is an annual event organised by Institution
          of Valuers (IOV).
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className={`accordion-button ${activeKey === 'collapseTwo' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseTwo')}
            aria-expanded={activeKey === 'collapseTwo'}
            aria-controls="collapseTwo"
          >
           <strong>(2)</strong> &nbsp; What is the theme of Indian Valuers Congress organised by IOV?
          </button>
        </h2>
        <div
          id="collapseTwo"
          className={`accordion-collapse collapse ${activeKey === 'collapseTwo' ? 'show' : ''}`}
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>

          The theme of Indian Valuers Congress 2024 is <strong>"Empowering Valuers:
Enhancing Capacity, Exploring Opportunities."</strong>
In the dynamic and ever-evolving field of valuation, the role of valuers is more critical
than ever. As the global economy shifts and new challenges arise, it is essential for
valuers to be equipped with the skills and knowledge needed to navigate these
changes effectively. The theme for this year’s IVC reflects our commitment to
fostering professional growth, expanding knowledge, and exploring the myriad
opportunities available to valuers today.

          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className={`accordion-button ${activeKey === 'collapseThree' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseThree')}
            aria-expanded={activeKey === 'collapseThree'}
            aria-controls="collapseThree"
          >
           <strong>(3)</strong> &nbsp; When and Where is IVC 2024 going to be held?
          </button>
        </h2>
        <div
          id="collapseThree"
          className={`accordion-collapse collapse ${activeKey === 'collapseThree' ? 'show' : ''}`}
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
          The Indian Valuers Congress 2024 will be held from 5th - 7th December at
          Gokulam Park Hotel &amp; Convention Centre, Banerji Road, Kaloor, Kochi, Kerala.
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFour">
          <button
            className={`accordion-button ${activeKey === 'collapseFour' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseFour')}
            aria-expanded={activeKey === 'collapseFour'}
            aria-controls="collapseFour"
          >
           <strong>(4)</strong> &nbsp; What is the significance of attending IVC 2024?
          </button>
        </h2>
        <div
          id="collapseFour"
          className={`accordion-collapse collapse ${activeKey === 'collapseFour' ? 'show' : ''}`}
          aria-labelledby="headingFour"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
          <p>Attending IVC 2024 is beneficial for you as it provides:</p>
          <br></br>
<ul>
    <li>
<strong>Networking Opportunities:</strong> Connect with industry leaders and expand your
professional circle, fostering new collaborations and business prospects
</li>

<li>
<strong>Knowledge Enhancement:</strong> Access expert insights through panel
discussions, staying updated on the latest trends and best practices in
Valuation.
</li>

<li>
<strong>Career Advancement:</strong> Earn CEP points and certifications, enhancing your
professional profile.
</li>
<li>
<strong>Industry Insights:</strong> Stay informed about regulatory updates and market
trends, gaining valuable intelligence to influence your Valuation practices.
</li>
<li>
<strong>Business Opportunities:</strong> Participate in exhibitions and sponsorships to
promote your services and forge meaningful connections with potential
clients.
</li>
</ul>
<p>
Attending IVC 2024 isn’t just about professional development; it’s a chance to
immerse yourself in a dynamic environment that fuels growth and innovation within
the Valuation industry.
</p>

          </div>
        </div>
      </div>

      
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFive">
          <button
            className={`accordion-button ${activeKey === 'collapseFive' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseFive')}
            aria-expanded={activeKey === 'collapseFive'}
            aria-controls="collapseFive"
          >
           <strong>(5)</strong> &nbsp; How do I register for this event?
          </button>
        </h2>
        <div
          id="collapseFive"
          className={`accordion-collapse collapse ${activeKey === 'collapseFive' ? 'show' : ''}`}
          aria-labelledby="headingFive"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
          For registration, please visit: <a rel='noreferrer' href='https://www.iovivc.com/register' target='_blank'> https://www.iovivc.com/register</a>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingSix">
          <button
            className={`accordion-button ${activeKey === 'collapseSix' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapseSix')}
            aria-expanded={activeKey === 'collapseSix'}
            aria-controls="collapseSix"
          >
           <strong>(6)</strong> &nbsp; What will be the charges/fees and modes of payment?
          </button>
        </h2>
        <div
          id="collapseSix"
          className={`accordion-collapse collapse ${activeKey === 'collapseSix' ? 'show' : ''}`}
          aria-labelledby="headingSix"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>The mode of payment for this event will be in online mode.</p>
            <ul>
                <li>Event Registration Fees For <strong>Members ₹ 10000 </strong> Inclusive GST</li>
                <li>Event Registration Fees For <strong>Non-Members ₹ 12000 </strong> Inclusive GST</li>
            </ul>

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading7">
          <button
            className={`accordion-button ${activeKey === 'collapse7' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse7')}
            aria-expanded={activeKey === 'collapse7'}
            aria-controls="collapse7"
          >
           <strong>(7)</strong> &nbsp; Whether there is any discount on Group Registration?
          </button>
        </h2>
        <div
          id="collapse7"
          className={`accordion-collapse collapse ${activeKey === 'collapse7' ? 'show' : ''}`}
          aria-labelledby="heading7"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>No discount will be offered in case of Group Registration.</p>
             

          </div>
        </div>
      </div>



      <div className="accordion-item">
        <h2 className="accordion-header" id="heading8">
          <button
            className={`accordion-button ${activeKey === 'collapse8' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse8')}
            aria-expanded={activeKey === 'collapse8'}
            aria-controls="collapse8"
          >
           <strong>(8)</strong> &nbsp; I have paid the registration fees but have not received any confirmation.
           What should I do?
          </button>
        </h2>
        <div
          id="collapse8"
          className={`accordion-collapse collapse ${activeKey === 'collapse8' ? 'show' : ''}`}
          aria-labelledby="heading8"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>Kindly mail us your query, with the proof of the payment made at
            <a rel='noreferrer' href='mailto:contact.ivc@iov.co.in' target='_blank'> contact.ivc@iov.co.in</a></p>
 

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading9">
          <button
            className={`accordion-button ${activeKey === 'collapse9' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse9')}
            aria-expanded={activeKey === 'collapse9'}
            aria-controls="collapse9"
          >
           <strong>(9)</strong> &nbsp; What is the last date to avail the early bird discount?
          </button>
        </h2>
        <div
          id="collapse9"
          className={`accordion-collapse collapse ${activeKey === 'collapse9' ? 'show' : ''}`}
          aria-labelledby="heading9"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>The last date to avail the early bird discount for IVC 2024 is 30th
            September, 2024.</p>
             

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading10">
          <button
            className={`accordion-button ${activeKey === 'collapse10' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse10')}
            aria-expanded={activeKey === 'collapse10'}
            aria-controls="collapse10"
          >
           <strong>(10)</strong> &nbsp; Can I register at the venue?
          </button>
        </h2>
        <div
          id="collapse10"
          className={`accordion-collapse collapse ${activeKey === 'collapse10' ? 'show' : ''}`}
          aria-labelledby="heading10"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>The mode of payment for this event will be in online mode.</p>
            <ul>
                <li>Event Registration Fees For <strong>Members ₹ 10000 </strong> Inclusive GST</li>
                <li>Event Registration Fees For <strong>Non-Members ₹ 12000 </strong> Inclusive GST</li>
            </ul>

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading11">
          <button
            className={`accordion-button ${activeKey === 'collapse11' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse11')}
            aria-expanded={activeKey === 'collapse11'}
            aria-controls="collapse11"
          >
           <strong>(11)</strong> &nbsp; What is the process to cancel my registration for this event?
          </button>
        </h2>
        <div
          id="collapse11"
          className={`accordion-collapse collapse ${activeKey === 'collapse11' ? 'show' : ''}`}
          aria-labelledby="heading11"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>Once registered, you cannot cancel your registration for the event.</p>
            

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading12">
          <button
            className={`accordion-button ${activeKey === 'collapse12' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse12')}
            aria-expanded={activeKey === 'collapse12'}
            aria-controls="collapse12"
          >
           <strong>(12)</strong> &nbsp; What if I am not available to attend the event in person, will there be an
           option to attend virtually?
          </button>
        </h2>
        <div
          id="collapse12"
          className={`accordion-collapse collapse ${activeKey === 'collapse12' ? 'show' : ''}`}
          aria-labelledby="heading12"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>There will be no provision for virtual screening of the event. The event will
            be held only in <strong> offline mode </strong>, at the designated venue.</p>
            

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading13">
          <button
            className={`accordion-button ${activeKey === 'collapse13' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse13')}
            aria-expanded={activeKey === 'collapse13'}
            aria-controls="collapse13"
          >
           <strong>(13)</strong> &nbsp; How do I reach the venue of IVC from the airport?
          </button>
        </h2>
        <div
          id="collapse13"
          className={`accordion-collapse collapse ${activeKey === 'collapse13' ? 'show' : ''}`}
          aria-labelledby="heading13"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>The distance from Cochin International Airport to the venue is 26 km, which
            is easily accessible through local transportation.</p>
            
          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading14">
          <button
            className={`accordion-button ${activeKey === 'collapse14' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse14')}
            aria-expanded={activeKey === 'collapse14'}
            aria-controls="collapse14"
          >
           <strong>(14)</strong> &nbsp; Is hotel accommodation provided for the attendees?
          </button>
        </h2>
        <div
          id="collapse14"
          className={`accordion-collapse collapse ${activeKey === 'collapse14' ? 'show' : ''}`}
          aria-labelledby="heading14"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>Hotel accommodation will not be provided by us. However, the details of
            the nearby hotels are available on the website, to ease your search.</p>
            

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading15">
          <button
            className={`accordion-button ${activeKey === 'collapse15' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse15')}
            aria-expanded={activeKey === 'collapse15'}
            aria-controls="collapse15"
          >
           <strong>(15)</strong> &nbsp; How can my organization become a sponsor of IVC 2024?
          </button>
        </h2>
        <div
          id="collapse15"
          className={`accordion-collapse collapse ${activeKey === 'collapse15' ? 'show' : ''}`}
          aria-labelledby="heading15"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>You can view all the details regarding sponsorship opportunities for IVC
            2024 by visiting <a href='https://iovivc.com/page/partners' rel='noopener noreferrer'> https://iovivc.com/page/partners </a> </p>
             

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading16">
          <button
            className={`accordion-button ${activeKey === 'collapse16' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse16')}
            aria-expanded={activeKey === 'collapse16'}
            aria-controls="collapse16"
          >
           <strong>(6)</strong> &nbsp; What is the Working language of the Congress?
          </button>
        </h2>
        <div
          id="collapse16"
          className={`accordion-collapse collapse ${activeKey === 'collapse16' ? 'show' : ''}`}
          aria-labelledby="heading16"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>The working language of this Congress is English.</p>
            

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading17">
          <button
            className={`accordion-button ${activeKey === 'collapse17' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse17')}
            aria-expanded={activeKey === 'collapse17'}
            aria-controls="collapse17"
          >
           <strong>(17)</strong> &nbsp; Are there any CEP points offered for attending the event?
          </button>
        </h2>
        <div
          id="collapse17"
          className={`accordion-collapse collapse ${activeKey === 'collapse17' ? 'show' : ''}`}
          aria-labelledby="heading17"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>12 CEP points will be offered to attendees of this event.</p>
            

          </div>
        </div>
      </div>


      <div className="accordion-item">
        <h2 className="accordion-header" id="heading18">
          <button
            className={`accordion-button ${activeKey === 'collapse18' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => handleToggle('collapse18')}
            aria-expanded={activeKey === 'collapse18'}
            aria-controls="collapse18"
          >
           <strong>(18)</strong> &nbsp; My question is not listed here. Whom do I contact?
          </button>
        </h2>
        <div
          id="collapse18"
          className={`accordion-collapse collapse ${activeKey === 'collapse18' ? 'show' : ''}`}
          aria-labelledby="heading18"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body" style={{ 'padding-left': '5%' }}>
            <p>Kindly mail us your concerns at <a href='mailto:ivc@iov.co.in'> contact.ivc@iov.co.in </a> </p>
            

          </div>
        </div>
      </div>


    </div>

                            


                            

                         </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default CmsPages;