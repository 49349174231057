import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { checkUndeNullBlank } from "../../ObjectExist";

const Why = () => {
  const [viewModal, setViewModal] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const toggleView = (name) => {
    setViewModal(!viewModal);
    if (checkUndeNullBlank(name)) {
      setViewImage(name);
    }
  };

  return (
    <section
      class="space-top space-bottom-shape background-image shape-mockup-wrap"
      style={{ backgroundImage: "url(./img/why_bg_1.jpg)" }}
    >
      <Modal
        isOpen={viewModal}
        toggle={viewModal}
        scrollable={true}
        className="addModal"
      >
        <ModalHeader toggle={toggleView} />
        <ModalBody className="d-flex justify-content-center">
          <img src={`./img/${viewImage}`} alt="iovivc" />
        </ModalBody>
      </Modal>

      <div class="container">
        <div class="row">
          <div class="col-xl-6">
            <div class="group-img">
              <div class="img-wrap img-2">
                <img src="./img/meeting_3.jpg" alt="iovivc" />
                <span
                  class="icon-btn popup-image cursor-pointer"
                  onClick={() => toggleView("meeting_3.jpg")}
                >
                  +
                </span>
              </div>
              <div class="img-wrap img-1">
                <img src="./img/meeting_1.jpg" alt="iovivc" />
                <span
                  class="icon-btn popup-image cursor-pointer"
                  onClick={() => toggleView("meeting_1.jpg")}
                >
                  +
                </span>
              </div>

              <div class="img-wrap img-3">
                <img src="./img/meeting_4.jpg" alt="iovivc" />
                <span
                  class="icon-btn popup-image cursor-pointer"
                  onClick={() => toggleView("meeting_4.jpg")}
                >
                  +
                </span>
              </div>
              <div class="img-wrap img-4">
                <img src="./img/meeting_2.jpg" alt="iovivc" />
                <span
                  class="icon-btn popup-image cursor-pointer"
                  onClick={() => toggleView("meeting_2.jpg")}
                >
                  +
                </span>
              </div>
            </div>
          </div>
          <div class="col-xl-6 text-sm-start text-left">
            <span class="sub-title2">
              <img
                class="title-shape"
                src="./img/title_shape.png"
                alt="title shape"
              />
            </span>
            <h4 class="sec-title-ndm mb-35">WHY TO ATTEND IVC? </h4>
            <p class="mb-35">
              1. Clarity on importance & purpose of Valuation and related
              relevant legal provisions. <br></br>
              2. Renowned Speakers with unbound experience and expertise in the
              fiels of Valuation. <br></br>
              3. Face-to-face exposure promoting <br></br>• Knowledge sharing{" "}
              <br></br>• Enriching experience <br></br>• Value addition{" "}
              <br></br>• Updation on the developments. <br></br>• Enhancing
              skills <br></br>• Broadening perspective <br></br>• Networking{" "}
              <br></br>• Expanding referral base <br></br>• Brand building{" "}
              <br></br>
              4. Insight on Valuation in Digital Age. <br></br>
              5. CEP Credit hours for Registered Valuers <br></br>
              6. Active Valuer credits for IOV Members <br></br>
            </p>
            <Link to="https://www.iovivc.com/register " className="btn-grad">
              Register
            </Link>
          </div>
        </div>
      </div>
      <div
        class="shape-mockup jump d-none d-xl-block"
        style={{ top: "34%", left: "0%" }}
      >
        <img src="./img/line-8.png" alt="iovivc" />
      </div>
      <div
        class="shape-mockup movingX d-none d-xxl-block"
        style={{ right: "24%", bottom: "20%" }}
      >
        <img src="./img/line-9.png" alt="iovivc" />
      </div>
    </section>
  );
};
export default Why;
