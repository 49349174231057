import React, { useState, useEffect } from 'react'
import { Container, Row, Card, CardHeader, CardTitle, Button, FormGroup, Form, Label, Col, CardBody, Spinner } from 'reactstrap'
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import PermissionNotAllowed from './PermissionNotAllowed';


const SiteSetting = (props) => {
    const [loader, setLoader] = useState(false);
    const [logo, setLogo] = useState("");
    const [footerlogo, setFooterlogo] = useState("");
    const [toprightlogo, setToprightlogo] = useState("");
    const [fav, setFav] = useState("");
    const [apiData, setApiData] = useState({});

    const { register: speakerInput, handleSubmit: speakerSubmit, formState: { errors }, reset, setValue } = useForm();
    const logoFileInput = (e) => {
        setLogo(e.target.files[0])
    }
    const toprightlogoInput = (e) => {
        setToprightlogo(e.target.files[0])
    }
    const footerlogoInput = (e) => {
        setFooterlogo(e.target.files[0])
    }
    const faviconFileInput = (e) => {
        setFav(e.target.files[0]);
    }

    const onApiInteg = () => {
        let data = { admin_id: 1 }
        setLoader(true);
        http.post(`admin/settings-view`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {

                setApiData(res.data)
                // reset(res.data.data)
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    let tempObj = {}
    if (checkData(apiData) && checkData(apiData.data)) {
        apiData.data.map((item, index) => {
            if (item.option_key === 'address') {
                [
                    { name: 'address', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'twitter_url') {
                [
                    { name: 'twitter_url', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'linkedin') {
                [
                    { name: 'linkedin', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'facebook') {
                [
                    { name: 'facebook', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'logo') {
                tempObj.logo = item.option_value
            } else if (item.option_key === 'favicon') {
                tempObj.favicon = item.option_value
            } else if (item.option_key === 'footer_logo') {
                tempObj.footer_logo = item.option_value
            } else if (item.option_key === 'top_right_logo') {
                tempObj.top_right_logo = item.option_value
            } else if (item.option_key === 'meta_title') {
                [
                    { name: 'meta_title', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'phone') {
                [
                    { name: 'phone', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'email_for_contact') {
                [
                    { name: 'email_for_contact', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'google_map_iframe') {
                [
                    { name: 'google_map_iframe', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'meta_keywords') {
                [
                    { name: 'meta_keywords', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            } else if (item.option_key === 'meta_description') {
                [
                    { name: 'meta_description', value: item.option_value },
                ].forEach(({ name, value }) => setValue(name, value))
            }
            return true
        });
    }
    useEffect(() => {
        onApiInteg();
    }, []);

    const onAddSpeaker = (data) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("admin_id", checkData(props.user) ? props.user.id : '');
        formData.append("address", data.address);
        formData.append("email_for_contact", data.email_for_contact);
        formData.append("phone", data.phone);
        formData.append("google_map_iframe", data.google_map_iframe);
        formData.append("twitter_url", data.twitter_url);
        formData.append("linkedin", data.linkedin);
        formData.append("facebook", data.facebook);
        formData.append("meta_title", data.meta_title);
        formData.append("footer_about", data.footer_about);
        formData.append("meta_keywords", data.meta_keywords);
        formData.append("meta_description", data.meta_description);
        formData.append("logo", checkUndeNullBlank(logo) ? logo : checkUndeNullBlank(tempObj.logo) ? tempObj.logo : '');
        formData.append("footer_logo", checkUndeNullBlank(footerlogo) ? footerlogo : checkUndeNullBlank(tempObj.footer_logo) ? tempObj.footer_logo : '');
        formData.append("top_right_logo", checkUndeNullBlank(toprightlogo) ? toprightlogo : checkUndeNullBlank(tempObj.top_right_logo) ? tempObj.top_right_logo : '');
        formData.append("favicon", checkUndeNullBlank(fav) ? fav : checkUndeNullBlank(tempObj.favicon) ? tempObj.favicon : '');

        http.post(`admin/settings-update`, formData).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                Swal.fire({ title: 'info', text: 'Settings Updated', icon: 'info', })
                onApiInteg();
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };


    return (
        <div className="app-body overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="border-0 shadow mb-3">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0" tag="h3">Manage Site Setting</CardTitle>
                </CardHeader>
            </Card>
            <Row>
                <div class="col-md-12 col-lg-12">
                    <div class="card events-card my-3">
                        <div class="card-header d-flex flex-wrap justify-content-between align-items-center">
                            <h3 class="mt-0">Site Setting</h3>
                        </div>
                        {(checkData(props.permissions) && props.permissions.Site_Settings.edit)?
                            <CardBody>
                                <Form onSubmit={speakerSubmit(onAddSpeaker)}>
                                    <Row className="mx-0  addModal">
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="metatitle">Meta title<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("meta_title")} className="form-control-sm form-control" placeholder="Meta title*" id="metatitle" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="metakeywords">Meta keywords<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("meta_keywords")} className="form-control-sm form-control" placeholder="Meta keywords*" id="metakeywords" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="email">Email<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("email_for_contact")} className="form-control-sm form-control" placeholder="email*" id="email" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="phone">Phone<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("phone")} className="form-control-sm form-control" placeholder="Phone*" id="phone" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="address">Address<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("address")} className="form-control-sm form-control" placeholder="Address*" id="address" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="twitter">Twitter URL<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("twitter_url")} className="form-control-sm form-control" placeholder="Twitter URL*" id="twitter" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="facebook">Facebook URL<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("facebook")} className="form-control-sm form-control" placeholder="Facebook URL*" id="facebook" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="linkedin">Linkedin URL<span className="text-danger">*</span></Label>
                                                <input {...speakerInput("linkedin")} className="form-control-sm form-control" placeholder="Linkedin URL*" id="linkedin" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="google_iframe">Google Iframe<span className="text-danger">*</span></Label>
                                                <textarea {...speakerInput("google_map_iframe")} rows={3} className="form-control-sm form-control" placeholder="Googlr Map Iframe*" id="google_iframe" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="small_description">Meta description<span className="text-danger">*</span></Label>
                                                <textarea {...speakerInput("meta_description")} rows={3} className="form-control-sm form-control" placeholder="Meta description*" id="small_description" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Label for="small_description">Footer About<span className="text-danger">*</span></Label>
                                                <textarea {...speakerInput("footer_about")} rows={3} className="form-control-sm form-control" placeholder="Footer About*" id="footer_about" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="uploadLogo_add">Upload Brand logo</Label>
                                                <input {...speakerInput("brandlogo")} type="file" onChange={logoFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                                {checkUndeNullBlank(tempObj.logo) &&
                                                    <div className='mt-2'>
                                                        <span>
                                                            <img className="img-fluid" src={apiData.path + '/' + tempObj.logo} alt="iovivc" width="60" />
                                                        </span>
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="uploadFavicon_add">Upload Favicon</Label>
                                                <input {...speakerInput("brandfavicon")} type="file" onChange={faviconFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadFavicon_add" />
                                                {checkUndeNullBlank(tempObj.favicon) &&
                                                    <div className='mt-2'>
                                                        <span>
                                                            <img className="img-fluid" src={apiData.path + '/' + tempObj.favicon} alt="iovivc" width="60" />
                                                        </span>
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="uploadLogo_white">Upload White logo</Label>
                                                <input {...speakerInput("whitelogo")} type="file" onChange={footerlogoInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_white" />
                                                {checkUndeNullBlank(tempObj.footer_logo) &&
                                                    <div className='mt-2'>
                                                        <span>
                                                            <img className="img-fluid" src={apiData.path + '/' + tempObj.footer_logo} alt="iovivc" width="60" />
                                                        </span>
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Label for="uploadLogo_secondary">Upload Secondary logo</Label>
                                                <input {...speakerInput("secondarylogo")} type="file" onChange={toprightlogoInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_secondary" />
                                                {checkUndeNullBlank(tempObj.top_right_logo) &&
                                                    <div className='mt-2'>
                                                        <span>
                                                            <img className="img-fluid" src={apiData.path + '/' + tempObj.top_right_logo} alt="iovivc" width="60" />
                                                        </span>
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} className="text-right">
                                            <Button className="vs-btn border-0" color="primary">Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                         :
                         <PermissionNotAllowed/> }
                    </div>
                </div>
            </Row>
        </div>
    )
}

export default SiteSetting