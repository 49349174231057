import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Button, InputGroup, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { encryptStorageLogin } from '../../EncStorage';
import http from '../../CommonAxios'
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    const { register: loginInput, handleSubmit: loginSubmit, formState: { errors } } = useForm();
    const onMerchantLogin = (data) => {
        setLoader(true);
        http.post(`/admin/login`, data).then((res) => {
            setLoader(false);
            if (res.data.s === 1) {
                encryptStorageLogin.setItem('userenc', JSON.stringify(res.data.data));
                navigate('/event')
            } else {
                Swal.fire({ icon: 'error', title: 'Error', text: res.data.message, });
            }
        }).catch(function (err) {
            setLoader(false);
        });
    };
    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <div className="p-0 LoginPage content-wrapper">
                        <Row>
                            {/* <Link href="#" className="brand-logo text-center py-3 py-lg-0 d-none" ><img src='./img/logo.png' alt="logo" style={{maxWidth:'200px'}} /></Link> */}
                            <Col sm={12} lg={7} className="d-none d-lg-flex align-items-center p-5">
                                <div className="d-lg-flex justify-content-center align-items-center px-5 w-100">
                                    {/* <img className="img-fluid" src='./img/footer_bg_1.jpg' alt="img" /> */}
                                </div>
                            </Col>
                            <Col sm={12} lg={5} className="d-flex align-items-center px-2 p-lg-5 bg-white">
                                <Card className="border-0 px-xl-3 mx-auto w-100">
                                    <Link to="#" className="brand-logo2 text-center py-3 py-lg-0" ><img src='./img/logo.png' alt="iovivc" style={{ maxWidth: '200px' }} /></Link>
                                    <CardBody className="align-items-center">
                                        <CardTitle tag="h3" className="text-center">Welcome </CardTitle>
                                        <CardTitle tag="h6" className="text-center mb-3">Login Here</CardTitle>
                                        {/* <p className="card-text text-center font-weight-bold">Login Here</p> */}
                                        <Form onSubmit={loginSubmit(onMerchantLogin)}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <input {...loginInput("email", { required: 'Please Enter Email' })} className="form-control" placeholder="Enter Email" id="email" />
                                            </FormGroup>
                                            <FormGroup>
                                                <div className="d-flex justify-content-between">
                                                    <Label for="password">Password</Label>
                                                </div>
                                                <InputGroup>
                                                    <input {...loginInput("password", { required: 'Please Enter Password' })} type="password" placeholder="Enter Password" className="form-control" id="password" />
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup>
                                                <Button className="btn-sm ctm-btn w-100 py-2" color="primary"> Sign in</Button>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default Login;