import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';


 


const AboutHome = () => {

  
  const calculateTimeLeft = () => {
    const difference = +new Date(`2024-12-05T11:30:00Z`) - +new Date();
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  
    return () => clearTimeout(timer);
  });


  return (
    <div>
      <section className="space">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-lg-0 mb-60">
              <div className="about-img4">
                <img src="./img/National-Seminar-new.jpg" alt="iovivc" />
                {/* <div className="speaker-count">
                                        <div className="counter-number">15</div>
                                        <span className="text">Iconic Speakers</span>
                                    </div> */}
              </div>
            </div>
            <div className="col-lg-7 ps-xl-5">
              <span className="sub-title2">
                More About Us
                <img
                  className="title-shape"
                  src="./img/title_shape.png"
                  alt="title shape"
                />
              </span>
              <h1 className="sec-title-ndm mb-30">About The Event</h1>
              <p className="mb-35">
                <strong>Indian Valuers Congress (IVC)</strong>, is an annual
                event organised by Institution of Valuers (IOV). It is a
                benchmarking platform in the careers of Valuers from all over
                India and overseas. In its endeavour for marking India&#39;s
                presence in valuation field globally, IVC provides a crucial
                platform for disseminating know-how on Valuation and showcasing
                the profound impact on the current practices of the valuation
                profession through interaction amongst the valuers and
                stakeholders beyond boundaries.
              </p>
              <div className="info-media-wrap border-0 mb-1">
                <div className="info-media col-md-12 col-12">
                  <div className="info-media_icon">
                    <i className="fas fa-calendar-alt"></i>
                  </div>
                  <div className="media-body">
                    <h4 className="info-media_title">When</h4>
                    <p className="info-media_text">
                      5th, 6th and 7th December 2024
                    </p>
                  </div>
                </div>

                <div className="info-media col-md-12 col-12">
                  {/* <div className="info-media_icon">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div> */}
                  <div className="media-body text-center">
                    {/* <h4 className="info-media_title">16th December 2022</h4> */}
                    <Link to="/register" className="btn-grad me-4">
                      Register Now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="info-media-wrap border-0">
                <div className="info-media col-md-12 col-12">
                  <div className="info-media_icon">
                    <a href="https://maps.app.goo.gl/ReUyhuBXqynj4o7H6" target="_blank" rel="noreferrer">
                      {" "}
                      <i className="fas fa-map-marker-alt custom-map-marker"></i>{" "}
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="info-media_title">Venue</h3>
                    <p className="info-media_text">
                      Gokulam Park Hotel &amp; Convention Centre, Banerji Road,
                      Kaloor, Kochi, Kerala - 682017.
                    </p>
                  </div>
                </div>
              </div>
              <div className="btn-group mytimer">
               
              <div className="container z-index-common">
          <ul
            className="event-counter style5 counter-list" >
            <li>
              <div className="day count-number">{timeLeft.days}</div>
              <span className="count-name">Days</span>
            </li>
            <li>
              <div className="hour count-number">{timeLeft.hours}</div>
              <span className="count-name">Hours</span>
            </li>
            <li>
              <div className="minute count-number">{timeLeft.minutes}</div>
              <span className="count-name">Minutes</span>
            </li>
            <li>
              <div className="seconds count-number">{timeLeft.seconds}</div>
              <span className="count-name">Seconds</span>
            </li>
          </ul>
        </div>

          

                {/* <a href="#" className="popup-video video-btn"><i className="fas fa-play"></i>Watch Video</a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHome;
